export const AboutFounderStorySection = () => import('../../components/about/FounderStorySection.vue' /* webpackChunkName: "components/about-founder-story-section" */).then(c => wrapFunctional(c.default || c))
export const AboutOurHighlightsSection = () => import('../../components/about/OurHighlightsSection.vue' /* webpackChunkName: "components/about-our-highlights-section" */).then(c => wrapFunctional(c.default || c))
export const AboutOurJourneySection = () => import('../../components/about/OurJourneySection.vue' /* webpackChunkName: "components/about-our-journey-section" */).then(c => wrapFunctional(c.default || c))
export const AboutOurMissionSection = () => import('../../components/about/OurMissionSection.vue' /* webpackChunkName: "components/about-our-mission-section" */).then(c => wrapFunctional(c.default || c))
export const ChatFreshChatScript = () => import('../../components/chat/FreshChatScript.vue' /* webpackChunkName: "components/chat-fresh-chat-script" */).then(c => wrapFunctional(c.default || c))
export const CommonArticleItem = () => import('../../components/common/ArticleItem.vue' /* webpackChunkName: "components/common-article-item" */).then(c => wrapFunctional(c.default || c))
export const CommonBannerButtons = () => import('../../components/common/BannerButtons.vue' /* webpackChunkName: "components/common-banner-buttons" */).then(c => wrapFunctional(c.default || c))
export const CommonContactFeatureCard = () => import('../../components/common/ContactFeatureCard.vue' /* webpackChunkName: "components/common-contact-feature-card" */).then(c => wrapFunctional(c.default || c))
export const CommonCountDownTimer = () => import('../../components/common/CountDownTimer.vue' /* webpackChunkName: "components/common-count-down-timer" */).then(c => wrapFunctional(c.default || c))
export const CommonDontHaveAnAccountSection = () => import('../../components/common/DontHaveAnAccountSection.vue' /* webpackChunkName: "components/common-dont-have-an-account-section" */).then(c => wrapFunctional(c.default || c))
export const CommonDropdown = () => import('../../components/common/Dropdown.vue' /* webpackChunkName: "components/common-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CommonFeatureCard = () => import('../../components/common/FeatureCard.vue' /* webpackChunkName: "components/common-feature-card" */).then(c => wrapFunctional(c.default || c))
export const CommonFeatureFlag = () => import('../../components/common/FeatureFlag.vue' /* webpackChunkName: "components/common-feature-flag" */).then(c => wrapFunctional(c.default || c))
export const CommonFeaturesSection = () => import('../../components/common/FeaturesSection.vue' /* webpackChunkName: "components/common-features-section" */).then(c => wrapFunctional(c.default || c))
export const CommonLoader = () => import('../../components/common/Loader.vue' /* webpackChunkName: "components/common-loader" */).then(c => wrapFunctional(c.default || c))
export const CommonModal = () => import('../../components/common/Modal.vue' /* webpackChunkName: "components/common-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonNewModal = () => import('../../components/common/NewModal.vue' /* webpackChunkName: "components/common-new-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonNotFound = () => import('../../components/common/NotFound.vue' /* webpackChunkName: "components/common-not-found" */).then(c => wrapFunctional(c.default || c))
export const CommonOfflineNotice = () => import('../../components/common/OfflineNotice.vue' /* webpackChunkName: "components/common-offline-notice" */).then(c => wrapFunctional(c.default || c))
export const CommonPressAndMediaFeatureCard = () => import('../../components/common/PressAndMediaFeatureCard.vue' /* webpackChunkName: "components/common-press-and-media-feature-card" */).then(c => wrapFunctional(c.default || c))
export const CommonStelaBreadcrumbs = () => import('../../components/common/StelaBreadcrumbs.vue' /* webpackChunkName: "components/common-stela-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const CommonStelaFaq = () => import('../../components/common/StelaFaq.vue' /* webpackChunkName: "components/common-stela-faq" */).then(c => wrapFunctional(c.default || c))
export const CommonStelaHeaderNavigations = () => import('../../components/common/StelaHeaderNavigations.vue' /* webpackChunkName: "components/common-stela-header-navigations" */).then(c => wrapFunctional(c.default || c))
export const CommonStelaInputSelect = () => import('../../components/common/StelaInputSelect.vue' /* webpackChunkName: "components/common-stela-input-select" */).then(c => wrapFunctional(c.default || c))
export const CommonStelaLink = () => import('../../components/common/StelaLink.vue' /* webpackChunkName: "components/common-stela-link" */).then(c => wrapFunctional(c.default || c))
export const CommonStelaPageFooter = () => import('../../components/common/StelaPageFooter.vue' /* webpackChunkName: "components/common-stela-page-footer" */).then(c => wrapFunctional(c.default || c))
export const CommonStelaPageHeader = () => import('../../components/common/StelaPageHeader.vue' /* webpackChunkName: "components/common-stela-page-header" */).then(c => wrapFunctional(c.default || c))
export const CommonStelaSideNavigations = () => import('../../components/common/StelaSideNavigations.vue' /* webpackChunkName: "components/common-stela-side-navigations" */).then(c => wrapFunctional(c.default || c))
export const CommonTab = () => import('../../components/common/Tab.vue' /* webpackChunkName: "components/common-tab" */).then(c => wrapFunctional(c.default || c))
export const CommonTabs = () => import('../../components/common/Tabs.vue' /* webpackChunkName: "components/common-tabs" */).then(c => wrapFunctional(c.default || c))
export const CommonVideoItem = () => import('../../components/common/VideoItem.vue' /* webpackChunkName: "components/common-video-item" */).then(c => wrapFunctional(c.default || c))
export const ContactFullContactSection = () => import('../../components/contact/FullContactSection.vue' /* webpackChunkName: "components/contact-full-contact-section" */).then(c => wrapFunctional(c.default || c))
export const ContactReportFraudForm = () => import('../../components/contact/ReportFraudForm.vue' /* webpackChunkName: "components/contact-report-fraud-form" */).then(c => wrapFunctional(c.default || c))
export const ContactReportFraudSection = () => import('../../components/contact/ReportFraudSection.vue' /* webpackChunkName: "components/contact-report-fraud-section" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterArticlesSection = () => import('../../components/help-center/ArticlesSection.vue' /* webpackChunkName: "components/help-center-articles-section" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterCratingInfoSection = () => import('../../components/help-center/CratingInfoSection.vue' /* webpackChunkName: "components/help-center-crating-info-section" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterFaqSection = () => import('../../components/help-center/FaqSection.vue' /* webpackChunkName: "components/help-center-faq-section" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterGettingStartedSection = () => import('../../components/help-center/GettingStartedSection.vue' /* webpackChunkName: "components/help-center-getting-started-section" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterGettingStartedSectionOne = () => import('../../components/help-center/GettingStartedSectionOne.vue' /* webpackChunkName: "components/help-center-getting-started-section-one" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterGettingStartedSectionThree = () => import('../../components/help-center/GettingStartedSectionThree.vue' /* webpackChunkName: "components/help-center-getting-started-section-three" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterGettingStartedSectionTwo = () => import('../../components/help-center/GettingStartedSectionTwo.vue' /* webpackChunkName: "components/help-center-getting-started-section-two" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterIndexSection = () => import('../../components/help-center/HelpCenterIndexSection.vue' /* webpackChunkName: "components/help-center-index-section" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterLayout = () => import('../../components/help-center/HelpCenterLayout.vue' /* webpackChunkName: "components/help-center-layout" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterHelpfulVideosSection = () => import('../../components/help-center/HelpfulVideosSection.vue' /* webpackChunkName: "components/help-center-helpful-videos-section" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterItemsWeDoNotShipSection = () => import('../../components/help-center/ItemsWeDoNotShipSection.vue' /* webpackChunkName: "components/help-center-items-we-do-not-ship-section" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterOceanShippingSection = () => import('../../components/help-center/OceanShippingSection.vue' /* webpackChunkName: "components/help-center-ocean-shipping-section" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterPackageHandlingInfoSection = () => import('../../components/help-center/PackageHandlingInfoSection.vue' /* webpackChunkName: "components/help-center-package-handling-info-section" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterSearchResultItem = () => import('../../components/help-center/SearchResultItem.vue' /* webpackChunkName: "components/help-center-search-result-item" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterSearchResultsSection = () => import('../../components/help-center/SearchResultsSection.vue' /* webpackChunkName: "components/help-center-search-results-section" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterVideosSection = () => import('../../components/help-center/VideosSection.vue' /* webpackChunkName: "components/help-center-videos-section" */).then(c => wrapFunctional(c.default || c))
export const InputsSearchInput = () => import('../../components/inputs/SearchInput.vue' /* webpackChunkName: "components/inputs-search-input" */).then(c => wrapFunctional(c.default || c))
export const LayoutStelaErrorPageLayout = () => import('../../components/layout/StelaErrorPageLayout.vue' /* webpackChunkName: "components/layout-stela-error-page-layout" */).then(c => wrapFunctional(c.default || c))
export const LayoutStelaPageLayout = () => import('../../components/layout/StelaPageLayout.vue' /* webpackChunkName: "components/layout-stela-page-layout" */).then(c => wrapFunctional(c.default || c))
export const ModalsBlackFridayModal = () => import('../../components/modals/BlackFridayModal.vue' /* webpackChunkName: "components/modals-black-friday-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsChinaAddressModal = () => import('../../components/modals/ChinaAddressModal.vue' /* webpackChunkName: "components/modals-china-address-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsDelawareAddressChangedModal = () => import('../../components/modals/DelawareAddressChangedModal.vue' /* webpackChunkName: "components/modals-delaware-address-changed-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsExtraLargeShipmentModal = () => import('../../components/modals/ExtraLargeShipmentModal.vue' /* webpackChunkName: "components/modals-extra-large-shipment-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsOceanShippingCountDownModal = () => import('../../components/modals/OceanShippingCountDownModal.vue' /* webpackChunkName: "components/modals-ocean-shipping-count-down-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsSelectCountryModal = () => import('../../components/modals/SelectCountryModal.vue' /* webpackChunkName: "components/modals-select-country-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsSubscriptionPromoModal = () => import('../../components/modals/SubscriptionPromoModal.vue' /* webpackChunkName: "components/modals-subscription-promo-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsWatchVideoModal = () => import('../../components/modals/WatchVideoModal.vue' /* webpackChunkName: "components/modals-watch-video-modal" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaAboutPageHeaderPanel = () => import('../../components/panels/StelaAboutPageHeaderPanel.vue' /* webpackChunkName: "components/panels-stela-about-page-header-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaBouncingScrollDown = () => import('../../components/panels/StelaBouncingScrollDown.vue' /* webpackChunkName: "components/panels-stela-bouncing-scroll-down" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaCallToActionPanel = () => import('../../components/panels/StelaCallToActionPanel.vue' /* webpackChunkName: "components/panels-stela-call-to-action-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaContactInfoPanel = () => import('../../components/panels/StelaContactInfoPanel.vue' /* webpackChunkName: "components/panels-stela-contact-info-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaEasyShippingPanel = () => import('../../components/panels/StelaEasyShippingPanel.vue' /* webpackChunkName: "components/panels-stela-easy-shipping-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaExpectShipmentPanel = () => import('../../components/panels/StelaExpectShipmentPanel.vue' /* webpackChunkName: "components/panels-stela-expect-shipment-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaFaqPanel = () => import('../../components/panels/StelaFaqPanel.vue' /* webpackChunkName: "components/panels-stela-faq-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaFeatureListPanel = () => import('../../components/panels/StelaFeatureListPanel.vue' /* webpackChunkName: "components/panels-stela-feature-list-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaHeaderPanel = () => import('../../components/panels/StelaHeaderPanel.vue' /* webpackChunkName: "components/panels-stela-header-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaHelpCenterPageHeaderPanel = () => import('../../components/panels/StelaHelpCenterPageHeaderPanel.vue' /* webpackChunkName: "components/panels-stela-help-center-page-header-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaLegalContentPanel = () => import('../../components/panels/StelaLegalContentPanel.vue' /* webpackChunkName: "components/panels-stela-legal-content-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaLegalPageHeaderPanel = () => import('../../components/panels/StelaLegalPageHeaderPanel.vue' /* webpackChunkName: "components/panels-stela-legal-page-header-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaNotificationBanner = () => import('../../components/panels/StelaNotificationBanner.vue' /* webpackChunkName: "components/panels-stela-notification-banner" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaOtherPricingInfoPanel = () => import('../../components/panels/StelaOtherPricingInfoPanel.vue' /* webpackChunkName: "components/panels-stela-other-pricing-info-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaPriceCalculatorPanel = () => import('../../components/panels/StelaPriceCalculatorPanel.vue' /* webpackChunkName: "components/panels-stela-price-calculator-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaPricingPageHeaderPanel = () => import('../../components/panels/StelaPricingPageHeaderPanel.vue' /* webpackChunkName: "components/panels-stela-pricing-page-header-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaRecommendedReadingPanel = () => import('../../components/panels/StelaRecommendedReadingPanel.vue' /* webpackChunkName: "components/panels-stela-recommended-reading-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaShippingFeeCoveragePanel = () => import('../../components/panels/StelaShippingFeeCoveragePanel.vue' /* webpackChunkName: "components/panels-stela-shipping-fee-coverage-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaShippingInfoPanel = () => import('../../components/panels/StelaShippingInfoPanel.vue' /* webpackChunkName: "components/panels-stela-shipping-info-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaShippingPriceInfoPanel = () => import('../../components/panels/StelaShippingPriceInfoPanel.vue' /* webpackChunkName: "components/panels-stela-shipping-price-info-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaSolutionInfoPanel = () => import('../../components/panels/StelaSolutionInfoPanel.vue' /* webpackChunkName: "components/panels-stela-solution-info-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaStoreIconPanel = () => import('../../components/panels/StelaStoreIconPanel.vue' /* webpackChunkName: "components/panels-stela-store-icon-panel" */).then(c => wrapFunctional(c.default || c))
export const PanelsStelaTestimonialPanel = () => import('../../components/panels/StelaTestimonialPanel.vue' /* webpackChunkName: "components/panels-stela-testimonial-panel" */).then(c => wrapFunctional(c.default || c))
export const ResourcesNavContent = () => import('../../components/resources/ResourcesNavContent.vue' /* webpackChunkName: "components/resources-nav-content" */).then(c => wrapFunctional(c.default || c))
export const ResourcesNavContentMobile = () => import('../../components/resources/ResourcesNavContentMobile.vue' /* webpackChunkName: "components/resources-nav-content-mobile" */).then(c => wrapFunctional(c.default || c))
export const SolutionsNavContent = () => import('../../components/solutions/SolutionsNavContent.vue' /* webpackChunkName: "components/solutions-nav-content" */).then(c => wrapFunctional(c.default || c))
export const SolutionsNavContentMobile = () => import('../../components/solutions/SolutionsNavContentMobile.vue' /* webpackChunkName: "components/solutions-nav-content-mobile" */).then(c => wrapFunctional(c.default || c))
export const SplitPayBenefits = () => import('../../components/split-pay/SplitPayBenefits.vue' /* webpackChunkName: "components/split-pay-benefits" */).then(c => wrapFunctional(c.default || c))
export const SplitPayFeatures = () => import('../../components/split-pay/SplitPayFeatures.vue' /* webpackChunkName: "components/split-pay-features" */).then(c => wrapFunctional(c.default || c))
export const SplitPayNotifications = () => import('../../components/split-pay/SplitPayNotifications.vue' /* webpackChunkName: "components/split-pay-notifications" */).then(c => wrapFunctional(c.default || c))
export const SplitPayUsages = () => import('../../components/split-pay/SplitPayUsages.vue' /* webpackChunkName: "components/split-pay-usages" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsCard = () => import('../../components/testimonials/TestimonialsCard.vue' /* webpackChunkName: "components/testimonials-card" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsLayout = () => import('../../components/testimonials/TestimonialsLayout.vue' /* webpackChunkName: "components/testimonials-layout" */).then(c => wrapFunctional(c.default || c))
export const TrackMyShipmentTimelineIllustration = () => import('../../components/track-my-shipment/ShipmentTimelineIllustration.vue' /* webpackChunkName: "components/track-my-shipment-timeline-illustration" */).then(c => wrapFunctional(c.default || c))
export const TrackMyShipmentLayout = () => import('../../components/track-my-shipment/TrackMyShipmentLayout.vue' /* webpackChunkName: "components/track-my-shipment-layout" */).then(c => wrapFunctional(c.default || c))
export const TrackMyShipmentResultSection = () => import('../../components/track-my-shipment/TrackMyShipmentResultSection.vue' /* webpackChunkName: "components/track-my-shipment-result-section" */).then(c => wrapFunctional(c.default || c))
export const TransitionsFadeTransition = () => import('../../components/transitions/FadeTransition.vue' /* webpackChunkName: "components/transitions-fade-transition" */).then(c => wrapFunctional(c.default || c))
export const TransitionsSlideTransition = () => import('../../components/transitions/SlideTransition.vue' /* webpackChunkName: "components/transitions-slide-transition" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
